body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 20px;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background: #f2f2f2;
    max-width: 100px;
}
ul li {
    display: inline-block;
}
ul li a {
    display: block;
    padding: 10px 25px;
    background: #ffffff;
    text-decoration: solid;
    text-align: left;
    border-top: 1px solid #ffffff;
    white-space: nowrap;
}
ul li a:hover {
    color: #fff;
    background: #939393;
}

.menu {
    right: 5%;
    top: 5px;
    margin-left: 100%;
    position: absolute;
}

.mainTitle {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 10px;
    font-size: 5vw;
    margin: 5px;
    block-size: auto;
    text-align: center;
    text-transform: uppercase;
    color:#0660e8;
}

.subTitle {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    font-size: 2vw;
    margin: 5%;
    text-align: center;
    color: #000000;
}

.container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.main {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: auto;
    flex-basis: 100%;
}

#homeContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    width:auto;
    position: relative;
    margin-top: 5%;
}

#homeFigure {
    position: relative;
    width: auto;
}

#homeIntro {
    margin-left: 5%;
    margin-top: 5px;
    font-size: larger;
    max-width: 30vw;
    position: relative;
    word-wrap: break-word;
}


figure.figurefx {
    width: auto;
    height: auto;
    margin-top: 10%;
    text-align: center;
    position: relative;
    overflow: hidden;
    /*  hide overflowing elements by default */
}

figure.figurefx figcaption {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    background: white;
    padding: 15px;
    z-index: 100;
    max-width: 100%;
    max-height: 100%;
    /* width: 100%;
    max-height: 100%; */
    top: 0%;
    margin-left: 40%;
    -moz-transform: translate3d(-100%, -50%, 0); /* position caption outside layout horizontally and centered vertically */
    -webkit-transform: translate3d(-100%, -50%, 0);
    transform: translate3d(-100%, -50%, 0);
    opacity: 0;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
  /*** Push Up effect ****/
figure.pushup img {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -moz-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
}

figure.pushup figcaption {
      top: 100%;
      opacity: 1;
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
}

figure.pushup:hover img {
      -moz-transform: translate3d(0, -20px, 0); /* move image up vertically a bit for parallax effect */
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
      opacity: .5;
}

figure.pushup:hover figcaption{
      -moz-transform: translate3d(0, -100%, 0); /* move caption up vertically to fully reveal caption */
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
      -moz-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      box-shadow: 0px -2px 28px rgba(0, 0, 0, 0.3);
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer img {
    height: 4vh;
    width: auto;
}
.header {
    height: 4vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}